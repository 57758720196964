import React from "react";
import { Link } from "react-router-dom";

const Card = () => {
  return (
    <div className="grid grid-cols-3 md:grid-cols-6 xl:grid-cols-6 gap-4">
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>{" "}
      <div className="w-full bg-white border border-gray-200 rounded-md shadow">
        <Link to="#">
          <img
            className="p-2 rounded-t-lg"
            src="https://images.unsplash.com/photo-1714752493908-bdd641f85d42?q=80&w=1858&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="product image"
          />
        </Link>
        <div className="text-center pb-2">
          <Link to="#">
            <h5 className="text-base font-semibold tracking-tight text-gray-900 ">
              Deatail
            </h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
